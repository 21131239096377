import React from 'react'

import {
  GlobalSettingsRecord,
  useGetTimeOffSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { TimeOffSettingsInterface } from '@src/interfaces/settings'
import PageLoading from '@src/components/PageLoading/PageLoading'
import PageAccessDenied from '@src/pages/PageAccessDenied/PageAccessDenied'

type CheckBySettings = {
  global?: (settings?: GlobalSettingsRecord) => boolean
  timeOff?: (settings?: TimeOffSettingsInterface) => boolean
}
type Props = {
  canView: boolean
  checkBySettings: CheckBySettings
  children: React.ReactNode
}
export const VisibilityWrapper = ({ canView, children, checkBySettings }: Props) => {
  const hasGlobalSettingsCheck = 'global' in checkBySettings
  const { settings: globalSettings, isLoading: isGlobalSettingsLoading } =
    useGlobalSettings(!hasGlobalSettingsCheck)

  const hasTimeOffSettingsCheck = 'timeOff' in checkBySettings
  const { data: timeOffSettings, isLoading: isTimeOffSettingsLoading } =
    useGetTimeOffSettings(!hasTimeOffSettingsCheck)

  const isAllowedByGlobalSettings = hasGlobalSettingsCheck
    ? checkBySettings.global?.(globalSettings)
    : true
  const isAllowedByTimeOffSettings = hasTimeOffSettingsCheck
    ? checkBySettings.timeOff?.(timeOffSettings)
    : true

  const isLoading = isGlobalSettingsLoading || isTimeOffSettingsLoading
  const isAllowed = canView && isAllowedByGlobalSettings && isAllowedByTimeOffSettings

  if (isLoading) {
    return <PageLoading />
  }
  if (!isAllowed) {
    return <PageAccessDenied />
  }
  return <>{children}</>
}
