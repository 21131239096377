import React, { useState } from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import { PerformanceOverview } from '@src/pages/Forms/Candidate/Performance/PerformanceOverview'
import { Token, VStack } from '@revolut/ui-kit'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { PerformanceSummary } from '@src/pages/Forms/Candidate/Performance/Summary/PerformanceSummary'
import { ScorecardSidebar } from '@src/pages/Forms/Candidate/Performance/Summary/ScorecardSidebar'

type PerformanceProps = {
  round?: InterviewRoundInterface
}

export const Performance = ({ round }: PerformanceProps) => {
  const [interviewFeedbackIds, setInterviewFeedbackIds] = useState<number[]>()
  const onCloseInterviewFeedbacks = () => {
    setInterviewFeedbackIds([])
  }
  return (
    <>
      <PageBody maxWidth={Token.breakpoint.lg}>
        <VStack gap="s-16">
          <PerformanceOverview round={round} />
          <PerformanceSummary
            round={round}
            onViewScorecard={feedbackIds => {
              setInterviewFeedbackIds(feedbackIds)
            }}
          />
        </VStack>
      </PageBody>
      {interviewFeedbackIds?.length && (
        <ScorecardSidebar
          interviewFeedbackIds={interviewFeedbackIds}
          round={round}
          onClose={onCloseInterviewFeedbacks}
        />
      )}
    </>
  )
}
