import { RowInterface, Stats } from '@src/interfaces/data'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import {
  createRequisitionTitleColumn,
  requisitionBackfillColumn,
  requisitionHeadcountColumn,
  requisitionIdColumn,
  requisitionQueuePosition,
  requisitionStatusColumn,
} from '@src/constants/columns/requisition'
import { specialisationRoleNameColumn } from '@src/constants/columns/role'
import { seniorityNameRequisitionsColumn } from '@src/constants/columns/seniority'
import { teamDepartmentColumn, teamNameColumn } from '@src/constants/columns/team'
import { lineManagerColumn, recruiterNameColumn } from '@src/constants/columns/employee'
import { locationNameRequisitionColumn } from '@src/constants/columns/location'
import { textChain } from '@revolut/ui-kit'
import { orderingCellsWrapper } from '@src/utils/ordering'

type Props = {
  isNewTable: boolean
  stats?: Stats
  isConfidential: boolean
  isOrdering?: boolean
  orderingMode?: boolean
  onChangePosition?: (id: number, position: number) => void
}

export const getCommonRequisitionTableRow = ({
  isNewTable,
  stats,
  isConfidential,
  orderingMode = false,
  onChangePosition = () => {},
}: Props): RowInterface<RequisitionInterface> => {
  return {
    linkToForm: orderingMode
      ? undefined
      : ({ id }) => navigateTo(pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id })),
    disabled: data =>
      data.status === Statuses.rejected || data.status === Statuses.closed,
    cells: orderingCellsWrapper<RequisitionInterface>(
      orderingMode,
      onChangePosition,
      !orderingMode,
      [
        {
          ...createRequisitionTitleColumn(isConfidential),
          width: 200,
        },
        {
          ...requisitionIdColumn,
          width: 70,
        },
        {
          ...specialisationRoleNameColumn,
          width: 160,
        },
        {
          ...seniorityNameRequisitionsColumn,
          width: 100,
        },
        {
          ...teamDepartmentColumn,
          width: 130,
        },
        {
          ...teamNameColumn,
          width: 130,
        },
        {
          ...lineManagerColumn,
          title: 'Hiring manager',
          width: 130,
        },
        {
          ...locationNameRequisitionColumn,
          width: 100,
        },
        {
          ...recruiterNameColumn,
          width: 130,
        },
        {
          ...requisitionHeadcountColumn,
          width: 130,
          title: isNewTable
            ? textChain(
                'Headcount',
                stats &&
                  `${stats.requisition_total_headcount}/${stats.requisition_remaining_headcount}`,
              )
            : 'Remaining headcount',
        },
        {
          ...requisitionQueuePosition,
          width: 100,
        },
        {
          ...requisitionStatusColumn,
          width: 100,
        },
        {
          ...requisitionBackfillColumn,
          width: 100,
        },
      ],
    ),
  }
}
